import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function SeeMoreResult(props) {
  const { t } = useTranslation();

  const infoText = () => {
    if (props.sum < 20) return t("SEE_MORE_RESULT_PALLIATIVE_INTENTION_KEY");
    if (props.sum >= 35) return t("SEE_MORE_RESULT_SPECIALIZED_PALLIATIVE_KEY");
    return t("SEE_MORE_RESULT_ASSOCIATED_PALLIATIVE_KEY");
  };

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (props.isInfoOpen && ref.current && !ref.current.contains(e.target)) {
        props.setIsInfoOpen(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        <span style={{ fontWeight: "bold" }}>{t(props.message)}</span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => props.setIsInfoOpen(false)}
        >
          X
        </span>
      </div>
      <div>{infoText()}</div>
    </div>
  );
}

export default SeeMoreResult;
