export const vasoactiveDrugs = [
  {
    label: "Dobutamina",
    value: 4,
    translation: "VASOACTIVE_DRUGS_DOBUTAMINE_KEY",
  },
  {
    label: "Noradrenalina/vasopressiva",
    value: 6,
    translation: "VASOACTIVE_DRUGS_NOREPINEPHRINE_KEY",
  },
  {
    label: "Adrenalina",
    value: 8,
    translation: "VASOACTIVE_DRUGS_ADRENALINE_KEY",
  },
  { label: "Dopamina", value: 2, translation: "VASOACTIVE_DRUGS_DOPAMINE_KEY" },
  {
    label: "Milrinone",
    value: 1,
    translation: "VASOACTIVE_DRUGS_MILRIONONE_KEY",
  },
];
