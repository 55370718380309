import React from "react";
import "./selectMultiple.css";
import { useTranslation } from "react-i18next";

function SelectMultiple(props) {
  const { t } = useTranslation();
  const { options, multiple, setMultiple } = props;

  const handleChange = (event) => {
    const toAdd = {
      label: event.target.name,
      value: Number(event.target.value),
    };

    if (multiple.length > 0) {
      const filtered = multiple.filter((m) => m.label !== toAdd.label); // Array que checa se o objeto da checkbox existe ou não no state recebido via props
      filtered.length === multiple.length
        ? setMultiple([...multiple, toAdd]) // Se os tamanhos foram iguais, significa que o objeto da checkbox não existe no state, então basta adicioná-lo ao state
        : setMultiple(filtered); // Se forem diferentes, significa que o objeto da checkbox existe no state, então para retirá-lo do state basta retornar o Array filtrado
    } else {
      setMultiple([toAdd]);
    }
  };

  return (
    <>
      {options.map((m) => {
        return (
          <div key={m.label} className="div-mult">
            <input
              type="checkbox"
              className="checkbox"
              id={m.label}
              name={m.label}
              value={m.value}
              onChange={handleChange}
            />
            {t(m.translation)}
          </div>
        );
      })}
    </>
  );
}
export default SelectMultiple;
