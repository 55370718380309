import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./forms.css";

import Information from "../information/information.jsx";
import SelectOption from "../selectOption/selectOption.jsx";
import SelectMultiple from "../selectMultiple/selectMultiple";
import ConfigButton from "../configButton/configButton";
import ProgressBar from "../progressBar/progressBar";
import { useTranslation } from "react-i18next";

import { birthWeight as dataBirthWeight } from "../../data/birthWeight";
import { clinicalConditions as dataClinicalConditions } from "../../data/clinicalConditions";
import { devices as dataDevices } from "../../data/devices";
import { limitingConditions as dataLimitingConditions } from "../../data/limitingConditions";
import { vasoactiveDrugs as dataVasoactiveDrugs } from "../../data/vasoactiveDrugs";
import { ventilatorySupport as dataVentilatorySupport } from "../../data/ventilatorySupport";
import { viability as dataViability } from "../../data/viability";

import { useRecoilValue } from "recoil";
import { themeSwitchState } from "../../atoms/themeSwitch/themeSwitchState";
import Modal from "../modal/modal";
import SeeMoreResult from "../modal/seeMoreResult/seeMoreResult";

function Forms() {
  const { t } = useTranslation();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [viability, setViability] = useState(dataViability[0].value);
  const [birthWeight, setBirthWeight] = useState(dataBirthWeight[0].value);
  const [limitingConditions, setLimitingConditions] = useState([]);
  const [clinicalConditions, setClinicalConditions] = useState([]);
  const [vasoactiveDrugs, setVasoactiveDrugs] = useState([]);
  const [ventilatorySupport, setVentilatorySupport] = useState([]);
  const [devices, setDevices] = useState([]);
  const [sum, setSum] = useState(0);
  const [message, setMessage] = useState("");
  const theme = useRecoilValue(themeSwitchState);

  useEffect(() => {
    const sumLimCond = limitingConditions.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    const sumClinCond = clinicalConditions.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    const sumVasoactiveDrugs = vasoactiveDrugs.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    const sumVentilatorySupp = ventilatorySupport.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    const sumDevices = devices.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);

    setSum(
      viability +
        birthWeight +
        sumLimCond +
        sumClinCond +
        sumVasoactiveDrugs +
        sumVentilatorySupp +
        sumDevices
    );
  }, [
    viability,
    birthWeight,
    limitingConditions,
    clinicalConditions,
    vasoactiveDrugs,
    ventilatorySupport,
    devices,
  ]);

  useEffect(() => {
    if (sum === 0) return setMessage("SCORE_PALINEO_TITLE_KEY");
    if (sum < 20) return setMessage("RESULT_PALLIATIVE_INTENTION_KEY");
    if (sum >= 35) return setMessage("RESULT_SPECIALIZED_PALLIATIVE_KEY");
    setMessage("RESULT_ASSOCIATED_PALLIATIVE_KEY");
  }, [sum]);

  const configProps = {
    setViability,
    setBirthWeight,
    setLimitingConditions,
    setClinicalConditions,
    setVasoactiveDrugs,
    setVentilatorySupport,
    setDevices,
    dataViability,
    dataBirthWeight,
  };

  return (
    <>
      <div className={`header ${theme}`}>
        <div className="progress-bar-with-config">
          <ConfigButton {...configProps} />
          <ProgressBar sum={sum}></ProgressBar>
        </div>
        <div className="title">
          {t(message)}
          &nbsp;
          {sum !== 0 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: "0.8em",
                  color: theme === "light-theme" ? "blue" : "#3DBDEB",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setIsInfoOpen(true)}
              >
                {t("SEE_MORE_BUTTON_KEY")}
              </span>
            </div>
          )}
          {isInfoOpen && (
            <Modal>
              <SeeMoreResult
                message={message}
                sum={sum}
                isInfoOpen={isInfoOpen}
                setIsInfoOpen={setIsInfoOpen}
              />
            </Modal>
          )}
        </div>
      </div>
      <Routes>
        <Route exact path="/information" element={<Information />} />
        <Route
          path="*"
          element={
            <div className={`App ${theme}`}>
              <div className="card">
                <div className="card-title">{t("VIABILITY_KEY")}</div>
                <SelectOption
                  options={dataViability}
                  setOption={setViability}
                ></SelectOption>
              </div>
              <div className="card">
                <div className="card-title">{t("BIRTH_WEIGHT_KEY")}</div>
                <SelectOption
                  options={dataBirthWeight}
                  setOption={setBirthWeight}
                ></SelectOption>
              </div>
              <div>
                <div className="card-title">{t("LIMITING_CONDITIONS_KEY")}</div>
                <div className="card-multiple">
                  <SelectMultiple
                    options={dataLimitingConditions}
                    multiple={limitingConditions}
                    setMultiple={setLimitingConditions}
                  ></SelectMultiple>
                </div>
              </div>
              <div>
                <div className="card-title">{t("CLINICAL_CONDITIONS_KEY")}</div>
                <div className="card-multiple">
                  <SelectMultiple
                    options={dataClinicalConditions}
                    multiple={clinicalConditions}
                    setMultiple={setClinicalConditions}
                  ></SelectMultiple>
                </div>
              </div>
              <div>
                <div className="card-title">{t("VASOACTIVE_DRUGS_KEY")}</div>
                <div className="card-multiple">
                  <SelectMultiple
                    options={dataVasoactiveDrugs}
                    multiple={vasoactiveDrugs}
                    setMultiple={setVasoactiveDrugs}
                  ></SelectMultiple>
                </div>
              </div>
              <div>
                <div className="card-title">{t("VENTILATORY_SUPPORT_KEY")}</div>
                <div className="card-multiple">
                  <SelectMultiple
                    options={dataVentilatorySupport}
                    multiple={ventilatorySupport}
                    setMultiple={setVentilatorySupport}
                  ></SelectMultiple>
                </div>
              </div>
              <div>
                <div className="card-title">{t("DEVICES_KEY")}</div>
                <div className="card-multiple">
                  <SelectMultiple
                    options={dataDevices}
                    multiple={devices}
                    setMultiple={setDevices}
                  ></SelectMultiple>
                </div>
              </div>
            </div>
          }
        />
      </Routes>
      <div
        className={theme}
        style={{
          borderTop: "1px solid black",
          padding: "8px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span>
          {t("ALL_RIGHTS_RESERVED_KEY")} | {t("DEVELOPED_BY_KEY")}{" "}
          <a
            style={{
              textDecoration: "none",
              color: theme === "light-theme" ? "blue" : "#3DBDEB",
              cursor: "pointer",
            }}
            href="https://www.linkedin.com/in/andrelebrao/"
          >
            André Lebrão Aigner Ribeiro
          </a>
          {" e "}
          <a
            style={{
              textDecoration: "none",
              color: theme === "light-theme" ? "blue" : "#3DBDEB",
              cursor: "pointer",
            }}
            href="https://www.linkedin.com/in/felipe-paganini-1ab7301b7/"
          >
            Felipe dos Santos Paganini
          </a>
        </span>
      </div>
    </>
  );
}

export default Forms;
