import React, { useState, useRef, useEffect } from "react";
import "./configButton.css";
import { MdMenu as HamburgerMenu } from "react-icons/md";
import DropdownConfig from "../dropdownConfig/dropdownConfig";
import { userLanguageState } from "../../atoms/language/language";
import { useRecoilValue } from "recoil";
import { themeSwitchState } from "../../atoms/themeSwitch/themeSwitchState";

function ConfigButton(props) {
  const [isVisible, setIsVisible] = useState(false);
  const languageSelected = useRecoilValue(userLanguageState);
  const theme = useRecoilValue(themeSwitchState);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isVisible && ref.current && !ref.current.contains(e.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  });

  useEffect(() => {
    setIsVisible(false);
  }, [languageSelected]);

  return (
    <div ref={ref}>
      <button
        className={`config-btn ${theme}`}
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        <HamburgerMenu
          size="20px"
          color={theme === "light-theme" ? "#282c34" : "#E7E9ED"}
        />
      </button>
      {isVisible && <DropdownConfig setIsVisible={setIsVisible} {...props} />}
    </div>
  );
}

export default ConfigButton;
