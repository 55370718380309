import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";

function Modal(props) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const MODAL_OVERLAY = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  };

  const MODAL_INFO = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",

    backgroundColor: "white",

    width: "80vw",

    padding: "1em",

    borderRadius: "16px",
  };

  return ReactDom.createPortal(
    <>
      <div style={MODAL_OVERLAY}></div>
      <div style={MODAL_INFO}>{props.children}</div>
    </>,
    document.getElementById("portal")
  );
}

export default Modal;
