export const birthWeight = [
  {
    label: "Acima de 1000gr",
    value: 0,
    translation: "BIRTH_WEIGHT_ABOVE_1000_KEY",
  },
  { label: "750 - 1000gr", value: 3, translation: "BIRTH_WEIGHT_750_1000_KEY" },
  { label: "500 - 749gr", value: 7, translation: "BIRTH_WEIGHT_500_749_KEY" },
  {
    label: "Abaixo de 500gr",
    value: 10,
    translation: "BIRTH_WEIGHT_BELOW_500_KEY",
  },
];
