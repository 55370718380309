export const ventilatorySupport = [
  {
    label: "Dependência de ventilação mecânica invasiva (PaO2/FiO2 ≥ 300)",
    value: 3,
    translation: "VENTILATORY_SUPPORT_ABOVE_300_KEY",
  },
  {
    label: "Dependência de ventilação mecânica invasiva (PaO2/FiO2 = 200-300)",
    value: 5,
    translation: "VENTILATORY_SUPPORT_200_300_KEY",
  },
  {
    label: "Dependência de ventilação mecânica invasiva (PaO2/FiO2 ≤ 200)",
    value: 10,
    translation: "VENTILATORY_SUPPORT_BELOW_200_KEY",
  },
  {
    label: "Uso de ventilação não invasiva",
    value: 3,
    translation: "VENTILATORY_SUPPORT_NON_INVASIVE_VENTILATION_KEY",
  },
  {
    label: "Necessidade de oxigenoterapia",
    value: 1,
    translation: "VENTILATORY_SUPPORT_OXYGEN_THERAPY_KEY",
  },
  {
    label: "Dependência de oxigenoterapia acima de 7 dias",
    value: 2,
    translation: "VENTILATORY_SUPPORT_OXYGEN_THERAPY_7_DAYS_KEY",
  },
];
