import { useState } from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Forms from "./components/forms/forms";

import Modal from "./components/modal/modal";
import ConsentTermModal from "./components/modal/consentTerm/consentTermModal";

function App() {
  const [isConsentTermOpen, setIsConsentTermOpen] = useState(true);

  return (
    <BrowserRouter>
      <RecoilRoot>
        {isConsentTermOpen && (
          <Modal>
            <ConsentTermModal
              setIsConsentTermOpen={setIsConsentTermOpen}
            ></ConsentTermModal>
          </Modal>
        )}
        <Forms />
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
