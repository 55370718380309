import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { themeSwitchState } from "../../../atoms/themeSwitch/themeSwitchState";

import "./consentTermModal.css";

function ConsentTermModal(props) {
  const [isChecked, setIsChecked] = useState(false);
  const theme = useRecoilValue(themeSwitchState);
  const { t } = useTranslation();

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="consent-term-display">
        <div className="consent-term-title">{t("CONSENT_TERMS_TITLE_KEY")}</div>
        <div className="consent-term-info">
          {t("CONSENT_TERMS_MODAL_TEXT_KEY")}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <input
            type="checkbox"
            style={{
              width: "2rem",
              height: "2rem",
              cursor: "pointer",
              accentColor: "green",
            }}
            onChange={handleChange}
          ></input>
          {t("CONSENT_TERMS_ACCEPT_KEY")}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            disabled={!isChecked}
            onClick={() => props.setIsConsentTermOpen(false)}
            style={{
              border: "1px solid black",
              display: "flex",
              padding: "8px",
              cursor: isChecked ? "pointer" : "not-allowed",
            }}
          >
            {t("CONFIRM_BUTTON_KEY")}
          </button>
        </div>
      </div>
    </>
  );
}

export default ConsentTermModal;
