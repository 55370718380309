import React from "react";
import "./dropdownConfig.css";
import { languages } from "../../i18n/languages";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { MdDarkMode as Moon, MdLightMode as Sun } from "react-icons/md";
import {
  BsCalculator as Calculator,
  BsFillInfoCircleFill as Info,
} from "react-icons/bs";
import { userLanguageState } from "../../atoms/language/language";
import { themeSwitchState } from "../../atoms/themeSwitch/themeSwitchState";
import { NavLink } from "react-router-dom";

function DropdownConfig(props) {
  const { t, i18n } = useTranslation();
  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  const [themeSwitcher, setThemeSwitcher] = useRecoilState(themeSwitchState);

  const handleLanguageChange = (event) => {
    setUserLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleThemeChange = () => {
    const newTheme =
      themeSwitcher === "light-theme" ? "dark-theme" : "light-theme";
    setThemeSwitcher(newTheme);
  };

  const resetValues = () => {
    props.setViability(props.dataViability[0].value);
    props.setBirthWeight(props.dataBirthWeight[0].value);
    props.setLimitingConditions([]);
    props.setClinicalConditions([]);
    props.setVasoactiveDrugs([]);
    props.setVentilatorySupport([]);
    props.setDevices([]);
  };

  return (
    <div className={`side-bar-menu ${themeSwitcher}`}>
      <div style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => props.setIsVisible(false)}
        >
          X
        </span>
      </div>

      <nav className="dropdown-navbar">
        <ul style={{ width: "100%", padding: 0 }}>
          <li className="dropdown-li">
            <NavLink className={`dropdown-link ${themeSwitcher}`} to="">
              {themeSwitcher === "light-theme" ? (
                <Calculator
                  size="0.8em"
                  color="#282c34"
                  style={{ marginRight: "4px" }}
                />
              ) : (
                <Calculator
                  size="20px"
                  color="#E7E9ED"
                  style={{ marginRight: "4px" }}
                />
              )}
              {t("CALCULATOR_TITLE_KEY")}
            </NavLink>
          </li>
          <li className="dropdown-li">
            <NavLink
              className={`dropdown-link ${themeSwitcher}`}
              to="/information"
              onClick={() => resetValues()}
            >
              {themeSwitcher === "light-theme" ? (
                <Info
                  size="0.8em"
                  color="#282c34"
                  style={{ marginRight: "4px" }}
                />
              ) : (
                <Info
                  size="20px"
                  color="#E7E9ED"
                  style={{ marginRight: "4px" }}
                />
              )}
              {t("INFORMATION_TITLE_KEY")}
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className="dropdown-config_language">
        <span style={{ fontWeight: "bold" }}>
          {t("CONFIGURATION_LANGUAGE_KEY")}
        </span>
        <select
          className={`dropdown-config_language-selector ${themeSwitcher}`}
          onChange={handleLanguageChange}
          defaultValue={userLanguage}
        >
          {languages.map((language) => {
            return (
              <option key={language} value={language}>
                {language}
              </option>
            );
          })}
        </select>
      </div>
      <div className="dropdown-config_theme">
        <button
          className={`dropdown-config_theme-button ${themeSwitcher}`}
          style={{ width: "100%", height: "30px" }}
          onClick={handleThemeChange}
        >
          {themeSwitcher === "light-theme" ? (
            <Sun size="20px" color="#282c34" />
          ) : (
            <Moon size="20px" color="#E7E9ED" />
          )}
        </button>
      </div>
    </div>
  );
}

export default DropdownConfig;
