export const limitingConditions = [
  {
    label: "Cardiopatias reversível",
    value: 2,
    translation: "LIMITING_CONDITIONS_REVERSIBLE_HEART_KEY",
  },
  {
    label: "Cardiopatias irreversível",
    value: 10,
    translation: "LIMITING_CONDITIONS_IRREVERSIBLE_HEART_KEY",
  },
  {
    label: "Anomalias estruturais orgânicas",
    value: 8,
    translation: "LIMITING_CONDITIONS_ORGANIC_ANOMALIES_KEY",
  },
  {
    label: "Anomalias graves de SNC",
    value: 10,
    translation: "LIMITING_CONDITIONS_SEVERE_ANOMALIES_KEY",
  },
  {
    label: "Displásia esquelética",
    value: 10,
    translation: "LIMITING_CONDITIONS_SKELETAL_DYSPLASIA_KEY",
  },
  {
    label: "Encefalopatia hipóxico isquêmica",
    value: 8,
    translation: "LIMITING_CONDITIONS_HYPOXIC_ENCEPHALOPATHY_KEY",
  },
  {
    label: "Hérnia diafragmática",
    value: 9,
    translation: "LIMITING_CONDITIONS_DIAPHRAGMATIC_HERNIA_KEY",
  },
  {
    label: "Hipoplasia pulmonar grave",
    value: 10,
    translation: "LIMITING_CONDITIONS_SEVERE_HYPOPLASIA_KEY",
  },
  {
    label: "Trissomia 13",
    value: 10,
    translation: "LIMITING_CONDITIONS_TRISOMY_13_KEY",
  },
  {
    label: "Trissomia 18",
    value: 10,
    translation: "LIMITING_CONDITIONS_TRISOMY_18_KEY",
  },
  {
    label: "Epidermolise bolhosa",
    value: 6,
    translation: "LIMITING_CONDITIONS_BULLOUS_KEY",
  },
  {
    label: "Fibrose cistica",
    value: 5,
    translation: "LIMITING_CONDITIONS_CYSTIC_FIBROSIS_KEY",
  },
  {
    label: "Osteogenese imperfeita grave",
    value: 6,
    translation: "LIMITING_CONDITIONS_SEVERE_OSTEOGENESIS_KEY",
  },
  {
    label: "Outras síndromes genéticas",
    value: 7,
    translation: "LIMITING_CONDITIONS_OTHER_SYNDROMES_KEY",
  },
  {
    label: "Condições metabólicas com baixa expectativa de vida",
    value: 6,
    translation: "LIMITING_CONDITIONS_METABOLIC_LOW_LIFE_KEY",
  },
];
