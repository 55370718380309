import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import PTBR from "./locales/pt-br/pt-br.json";
import ENUS from "./locales/en-us/en-us.json";
import ES from "./locales/es/es.json";

i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": { translation: PTBR },
    "en-US": { translation: ENUS },
    es: { translation: ES },
  },
  lng: "pt-BR",
  fallbackLng: "en-us",
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
