import React from "react";
import "./progressBar.css";

function ProgressBar(props) {
  const { sum } = props;
  return (
    <div className="progress-bar">
      <span
        className="fill"
        style={{
          width: `${sum > 35 ? 100 : (sum / 35) * 100}%`,
          backgroundColor: `${
            sum < 20 ? "green" : sum <= 34 ? "yellow" : "orange"
          }`,
        }}
      ></span>
    </div>
  );
}

export default ProgressBar;
