export const viability = [
  {
    label: "Acima de 30 semanas",
    value: 0,
    translation: "VIABILITY_ABOVE_30_WEEKS_KEY",
  },
  {
    label: "28 1/7 - 30 semanas",
    value: 4,
    translation: "VIABILITY_28_30_WEEKS_KEY",
  },
  {
    label: "25 1/7 - 28 semanas",
    value: 7,
    translation: "VIABILITY_25_28_WEEKS_KEY",
  },
  {
    label: "Abaixo de 25 semanas",
    value: 10,
    translation: "VIABILITY_BELOW_25_WEEKS_KEY",
  },
];
