import React from "react";
import { useRecoilValue } from "recoil";

import "./information.css";

import { useTranslation } from "react-i18next";

import { themeSwitchState } from "../../atoms/themeSwitch/themeSwitchState";

function Information() {
  const { t } = useTranslation();
  const theme = useRecoilValue(themeSwitchState);

  return (
    <>
      <div className={`App ${theme}`}>
        <div className="card-title">{t("INSTRUCTION_FOR_USE_KEY")}</div>
        <div className="card-text">
          {t("CONSENT_TERMS_DESCRIPTION_FIRST_PARAGRAPH_KEY")}
        </div>
        <div className="card-text">
          {t("CONSENT_TERMS_DESCRIPTION_SECOND_PARAGRAPH_KEY")}
        </div>
        <div className="card-text">
          {t("CONSENT_TERMS_DESCRIPTION_THIRD_PARAGRAPH_KEY")}
        </div>
        <div className="card-title" style={{ marginTop: "0.5em" }}>
          {t("REFERENCE_TITLE_KEY")}
        </div>
        <div className="card-text">{t("REFERENCE_TEXT_KEY")}</div>
      </div>
    </>
  );
}

export default Information;
