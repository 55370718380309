export const clinicalConditions = [
  {
    label: "Apgar do 5º minuto < 5",
    value: 10,
    translation: "CLINICAL_CONDITIONS_APGAR_KEY",
  },
  {
    label: "Insuficiência renal com indicação dialítica",
    value: 9,
    translation: "CLINICAL_CONDITIONS_RENAL_FAILURE_KEY",
  },
  {
    label: "Insuficiência hepática",
    value: 5,
    translation: "CLINICAL_CONDITIONS_LIVER_FAILURE_KEY",
  },
  {
    label: "Hemorragia intraperiventricular grau III",
    value: 7,
    translation: "CLINICAL_CONDITIONS_GRADE_3_KEY",
  },
  {
    label: "Hemorragia intraperiventricular grau IV",
    value: 10,
    translation: "CLINICAL_CONDITIONS_GRADE_4_KEY",
  },
  {
    label: "Leucomalacia",
    value: 6,
    translation: "CLINICAL_CONDITIONS_LEUKOMALACIA_KEY",
  },
  {
    label: "Hemorragia pulmonar",
    value: 6,
    translation: "CLINICAL_CONDITIONS_PULMONARY_HEMORRHAGE_KEY",
  },
  {
    label: "Parada cardiorespiratória prévia acima de 20 minutos",
    value: 8,
    translation: "CLINICAL_CONDITIONS_PREVIOUS_CARDIOPULMONARY_KEY",
  },
];
