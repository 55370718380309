import React from "react";
import "./selectOption.css";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { themeSwitchState } from "../../atoms/themeSwitch/themeSwitchState";

function SelectOption(props) {
  const { t } = useTranslation();
  const theme = useRecoilValue(themeSwitchState);
  const { options, setOption } = props;

  const handleChange = (event) => {
    setOption(Number(event.target.value));
  };

  return (
    <select
      aria-label="selector"
      className={`select-option_select ${theme}`}
      onChange={handleChange}
    >
      {options.map((option) => {
        return (
          <option key={option.label} value={option.value}>
            {t(option.translation)}
          </option>
        );
      })}
    </select>
  );
}

export default SelectOption;
